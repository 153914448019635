import React from "react";
import { graphql, Link } from "gatsby";
import moment from "moment";
import Layout from "../components/common/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import "../styles/pages/post.scss";
import Pagination from "../components/common/Pagination";
import Seo from "../components/meta/Seo";

const Posts = ({ data, pageContext }) => {
  const { post } = data;
  return (
    <div className="post">
      <Seo page={post} />
      <Layout>
        <div className="max-width post-container">
          <div className="post-contents">
            <div className="before">
              <Pagination
                pageInfo={data.posts.pageInfo}
                base={pageContext.base}
                allSlugs={pageContext.allSlugs}
                enableArrows
              />
            </div>
            <div className="post-image">
              <GatsbyImage
                image={data.posts.nodes[0].data.post_image.gatsbyImageData}
                style={{ borderRadius: 8 }}
              />
            </div>
            <div className="post-content">
              <h3 className="post-date">
                {moment(data.posts.nodes[0].first_publication_date).format(
                  "MMMM Do YYYY"
                )}
              </h3>
              <h1 className="post-title">
                {data.posts.nodes[0].data.post_title.text}
              </h1>
              <PrismicRichText
                field={data.posts.nodes[0].data.post_content.richText}
              />
            </div>
            <div className="after">
              <Pagination
                pageInfo={data.posts.pageInfo}
                base={pageContext.base}
                allSlugs={pageContext.allSlugs}
                enableArrows
              />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query postQuerys($lang: String, $limit: Int!, $skip: Int!, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    post: prismicPosts(lang: { eq: $lang }, id: { eq: $id }) {
      url
      type
      data {
        meta_title
        meta_description
        post_image {
          alt
          gatsbyImageData
        }
        post_title {
          text
        }
        post_content {
          richText
        }
      }
      first_publication_date
    }
    posts: allPrismicPosts(
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $lang } }
    ) {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
      nodes {
        data {
          post_image {
            alt
            gatsbyImageData
          }
          post_title {
            text
          }
          post_content {
            richText
          }
        }
        first_publication_date
      }
    }
  }
`;

export default Posts;
